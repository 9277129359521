<template>
	<div class="page_box">
		<password_edit_modal ref="password_edit_modal" />

		<div class="bom_box">

			<div class="account-titlebox">
				<div class="title">{{page_title}}</div>
			</div>

			<div class="bom_box_list">
				<div class="form-box">
					<div class="form-item">
						<div class="label">头像</div>
						<div class="avatar-box">
							<el-upload class="upload-avatar" name="file" :action="uploadAction" :data="uploadExtraData"
								:on-success="onSuccessUpload" :file-list="fileList">
								<!-- <button class="btn-upload">上传</button> -->
								<img :src="myAvatar || avatar_default" alt="" class="user-avatar" />
							</el-upload>

						</div>
					</div>
					<div class="form-item">
						<div class="label">姓名</div>
						<div class="text">{{ my_info.name }}</div>
					</div>

					<div class="form-item">
						<div class="label">账号</div>
						<div class="text">
							{{ my_info.username }}
						</div>
					</div>

					<div class="form-item">
						<div class="label">角色</div>
						<div class="text">{{ role_info.name }}</div>
					</div>
					<div class="form-item">
						<div class="label">密码</div>
						<div class="text">******</div>
						<div class="btn-box">
							<el-button type="text" @click="open_pass_edit()">修改密码</el-button>
						</div>
					</div>


					<div class="form-item" v-if="false">
						<div class="label" style="visibility: hidden;">保存</div>
						<!-- <div class="text">******</div> -->
						<div class="submit-box">
							<button @click="do_save()">保存</button>
						</div>
					</div>

					<!-- <div class="tip">
										密码要求至少包含字母，符号或数字中的两项且长度超过6位，
										<br />
										建议您经常修改密码，以保证帐号更加安全。
									</div> -->
				</div>

			</div>

		</div>

	</div>
</template>
<script>
	import password_edit_modal from '@/components/account/password_edit_modal.vue'

	import {
		mapState
	} from 'vuex'
	export default {
		name: 'edit_info',
		components: {
			password_edit_modal
		},
		data() {
			return {
				my_info: {},
				role_info: {},


				avatar_default: require("@/assets/img/avatar.png"),
				fileList: [],
				list_upload: [],
				myAvatar: ''
			};
		},
		computed: {
			...mapState(['vuex_user']),
			uploadAction() {
				let ret = process.env.NODE_ENV !== "production" ? "/api/upload" : "/api/upload";
				return ret;
			},

			uploadExtraData() {
				let token = "";
				if (localStorage && localStorage.getItem("token")) {
					token = localStorage.getItem("token");
				}
				return {
					token: token,
				};
			},
		},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.setView()
		},
		mounted() {

		},

		methods: {
			setView() {
				this.query_user()
			},
			query_user() {
				this.$api('getAdminInfo', {}, "get").then((res) => {
					if (res.code == 200) {
						this.my_info = res.data
						// commit('set_vuex_user', res.data)
						if (res.data.role_id) {
							// dispatch('query_role', res.data)
							// dispatch('query_notice')

							this.query_role()
						}
					}
				})
			},
			query_role() {
				this.$api('getAdminRole', {
					id: this.my_info.role_id
				}, "get").then((res) => {
					if (res.code == 200) {
						this.role_info = res.data;
						if (res.data.roles) {

							//控制菜单权限
						}
					}
				})
			},
			open_pass_edit() {
				// this.$refs.password_edit_modal.init();
				this.$router.push('/changePassword')
			},
			do_save() {

			},
			onSuccessUpload(res, file) {
				// debugger
				console.log("上传结果 res", res);
				console.log("上传结果 file", file);

				if (res.code == 200) {
					let url = res.data.path
					
					let cache_user = localStorage.getItem('cache_user')
					if(cache_user) {
						cache_user = JSON.parse(cache_user)
					}
					this.$api(
						"editAdmin", {
							// id: localStorage.getItem(''),
							id: cache_user.id,
							image: url,
						},
						"post"
					).then((res) => {
						if (res.code == 200) {
							this.myAvatar = url;
							this.$api('getAdminInfo', {}, "get").then((res) => {
								if (res.code == 200) {
									this.$store.commit('set_vuex_user', res.data)
								}
							})
						}
					});
				}
			},
		},
	};
</script>

<style scoped lang="less">
	.account-titlebox {
		.title {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			padding: 12px 17px;
			border-bottom: 1px solid #EDF0F6;
		}
	}

	.page_box {
		height: 100%;

		// background-color: red;
		.bom_box {
			padding: 20px;
		}

		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;



			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}


		}

		.bom_box {
			background: #FFFFFF;
		}


	}




	.form-box {
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.avatar-box {
		margin-bottom: 35px;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 120px;
			height: 120px;
			object-fit: cover;
		}
	}


	.form-item {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		align-self: flex-start;

		.label {
			min-width: 100px;
			margin-right: 40px;

			font-size: 16px;
			color: #333;
		}

		.text {
			font-size: 16px;
			color: #333;
		}

		.btn-box {
			margin-left: 100px;

			button {}
		}

		.submit-box {
			button {
				width: 91px;
				height: 31px;
				background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
				border-radius: 4px 4px 4px 4px;
				cursor: pointer;
				line-height: 31px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				text-align: center;

				&:hover {

					background: linear-gradient(90deg, #036ad8 0%, #157DE9 100%);
				}
			}
		}
	}

	.tip {
		font-size: 14px;
		color: #000000;
	}
</style>